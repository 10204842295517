import React, { Component, createRef } from 'react';

import { MartyContext } from 'utils/context';

import css from 'styles/components/productdetail/ask/itemForm.scss';

interface Props {
  authenticated: boolean | undefined;
  onAskAuthRedirect: () => void;
  onSubmitAskItem: (parentKey?: string | null, text?: string) => void;
  questionKey: string | null;
  shown: boolean;
  textareaPlaceholder: string;
}

interface State {
  value: string;
}

/**
 * This form is for both asking questions and writing answers. It knows which
 * one it is because an instance created for the "ask a question" element has a
 * questionKey prop of `null`. Instances created for "write an answer" elements
 * have the key of the question they answer as their questionKey prop.
 */
export default class ItemForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.authRedirect = this.authRedirect.bind(this);
    this.keyRedirect = this.keyRedirect.bind(this);
    this.textAreaRef = createRef();
  }

  state = { value: '' };

  componentDidMount() {
    if (this.textAreaRef.current && this.props.shown === true) {
      this.textAreaRef.current.focus();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.textAreaRef.current && prevProps.shown === false && this.props.shown === true) {
      this.textAreaRef.current.focus();
    }
  }
  textAreaRef: React.RefObject<HTMLTextAreaElement>;

  authRedirect() {
    this.props.onAskAuthRedirect();
  }

  handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const { onSubmitAskItem, questionKey } = this.props;
    event.preventDefault();
    onSubmitAskItem(questionKey, this.state.value);
    this.setState({ value: '' });
  }

  keyRedirect(e: React.KeyboardEvent<HTMLAnchorElement>) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.authRedirect();
    }
  }

  renderNotAuthenticated() {
    const { onAskAuthRedirect, questionKey } = this.props;
    const anchorProps = {
      tabIndex: 0,
      onClick: onAskAuthRedirect,
      onKeyDown: this.keyRedirect
    };

    if (questionKey) {
      return (
        <MartyContext.Consumer>
          {({ testId }) => (
            <p className={css.notAuthedMessage} data-test-id={testId('askNotAuthMsgAnswer')}>
              You need to be <a {...anchorProps}>signed in</a> or <a {...anchorProps}>create an account</a> before answering a question.
            </p>
          )}
        </MartyContext.Consumer>
      );
    } else {
      return (
        <MartyContext.Consumer>
          {({ testId }) => (
            <p className={css.notAuthedMessage} data-test-id={testId('askNotAuthMsgQuestion')}>
              Please <a {...anchorProps}>Log In</a> or <a {...anchorProps}>Create An Account</a> to post a question.
            </p>
          )}
        </MartyContext.Consumer>
      );
    }
  }

  render() {
    if (!this.props.authenticated) {
      return this.renderNotAuthenticated();
    }

    const { questionKey } = this.props;
    const submitText = questionKey ? 'Submit' : 'Ask';

    const textareaProps = {
      className: css.textarea,
      value: this.state.value,
      onChange: this.handleChange,
      placeholder: this.props.textareaPlaceholder,
      maxLength: 300,
      ref: this.textAreaRef,
      required: true
    };

    return (
      <MartyContext.Consumer>
        {({ testId }) => (
          <form method="post" className={css.form} onSubmit={this.handleSubmit}>
            {!questionKey && (
              <div className={css.askQuestionTopText}>
                <p>Have a question about this item? Want to get insights from others who bought it? Ask here!</p>
                <p>You'll be notified by email when someone's answered your question.</p>
                <label className={css.yourQuestionLabel} htmlFor={submitText} data-test-id={testId('questionHeader')}>
                  Your Question
                </label>
              </div>
            )}
            {questionKey && (
              <label className={css.yourQuestionLabel} htmlFor={submitText} data-test-id={testId('answerHeader')}>
                Your Answer
              </label>
            )}
            <textarea {...textareaProps} id={submitText} data-test-id={testId('askAnswerQuestionInput')} />
            <input
              className={css.submit}
              type="submit"
              value={submitText}
              aria-label={`Submit ${questionKey ? 'Answer' : 'Question'}`}
              data-test-id={testId(`${submitText}Button`)}
            />
          </form>
        )}
      </MartyContext.Consumer>
    );
  }
}

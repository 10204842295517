const makeQuestions = ({ question, answer }) => {
  const questions = [];

  if (question && question.key) {
    const questionId = question.key;

    if (answer && answer.key) {
      // we're tracking an answer, so don't track the question
      questions.push({ questionId, answerId: answer.key });
    } else {
      // we're tracking the question
      questions.push({ questionId });
    }
  }

  return questions;
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ProductQuestionAnswerImpression.proto
 *
 * @param {string} productId
 * @param {string} colorId
 * @param {object} question
 * @param {object} answer
 * @param {boolean} viewableImpression
 */
export const evProductQuestionAnswerImpression = ({ productId, colorId, question, answer, viewableImpression }) => ({
  productQuestionAnswerImpression: {
    questions: makeQuestions({ question, answer }),
    viewableImpression,
    productIdentifiers: {
      productId,
      colorId
    }
  }
});

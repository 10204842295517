import React from 'react';

import useMartyContext from 'hooks/useMartyContext';
import ProductAwareAmethystViewableImpression from 'components/productdetail/ProductAwareAmethystViewableImpression';
import VoteBox from 'components/productdetail/ask/VoteBox';
import { parseTextToReact } from 'helpers/parseToReact';
import { evProductQuestionAnswerImpression } from 'events/ask';
import type { AskAnswer, AskQuestion } from 'reducers/ask/ask';
import type { ProductDetailState } from 'reducers/detail/productDetail';

import css from 'styles/components/productdetail/ask/answer.scss';

interface Props {
  answer: AskAnswer;
  onAddAskDownvote: (key: string) => void;
  onAddAskUpvote: (key: string) => void;
  onReportAskAnswer: (key: string) => void;
  product: ProductDetailState;
  question: AskQuestion;
}

const Answer = ({ answer, onReportAskAnswer, onAddAskDownvote, onAddAskUpvote, product, question }: Props) => {
  const { author, best, date, key, text } = answer;
  const { testId } = useMartyContext();
  const bestAnswerHeading = (
    <div className={css.bestAnswerHeading} data-test-id={testId('bestAnswer')}>
      Best Answer
    </div>
  );
  const answerId = `customerAnswer${key}`;
  return (
    <ProductAwareAmethystViewableImpression
      key={key}
      event={evProductQuestionAnswerImpression}
      question={question}
      answer={answer}
      product={product}
    >
      <div className={css.answer}>
        {best && bestAnswerHeading}
        <p id={answerId}>{parseTextToReact(text)}</p>
        <div className={css.meta}>
          {parseTextToReact(author)} on {date}
        </div>
        <VoteBox
          item={answer}
          isQuestion={false}
          onAddAskDownvote={onAddAskDownvote}
          onAddAskUpvote={onAddAskUpvote}
          onReportAskItem={onReportAskAnswer}
          describedbyId={answerId}
        />
      </div>
    </ProductAwareAmethystViewableImpression>
  );
};

export default Answer;

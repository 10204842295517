import React from 'react';

import Answer from 'components/productdetail/ask/Answer';
import ItemForm from 'components/productdetail/ask/ItemForm';
import useMartyContext from 'hooks/useMartyContext';
import type { AskQuestion } from 'reducers/ask/ask';
import type { ProductDetailState } from 'reducers/detail/productDetail';

import css from 'styles/components/productdetail/ask/answerList.scss';

interface Props {
  authenticated: boolean | undefined;
  describedbyId: string;
  onAskAuthRedirect: () => void;
  onAddAskDownvote: (key: string) => void;
  onAddAskUpvote: (key: string) => void;
  onReportAskAnswer: (key: string) => void;
  onSetAnsweringAskQuestion: (key: string, answering: boolean) => void;
  onSetAskAnswersCollapsed: (questionKey: string, collapsed: boolean) => void;
  onSubmitAskItem: (parentKey?: string | null, text?: string) => void;
  product: ProductDetailState;
  question: AskQuestion;
}

const AnswerList = ({
  authenticated,
  describedbyId,
  onAskAuthRedirect,
  onAddAskDownvote,
  onAddAskUpvote,
  onReportAskAnswer,
  onSetAnsweringAskQuestion,
  onSetAskAnswersCollapsed,
  onSubmitAskItem,
  product,
  question
}: Props) => {
  const { answering, answers, answersCollapsed, key: questionKey } = question;
  const { testId } = useMartyContext();
  const answerEls = answers.map(a => (
    <Answer
      answer={a}
      key={a.key}
      onAddAskDownvote={onAddAskDownvote}
      onAddAskUpvote={onAddAskUpvote}
      onReportAskAnswer={onReportAskAnswer}
      product={product}
      question={question}
    />
  ));
  const collapsibleMarkup = !answersCollapsed ? <div>{answerEls.slice(1)}</div> : null;
  const seeOrHide = answersCollapsed ? 'See' : 'Hide';
  let collapserMarkup = null;
  if (answerEls.length > 1) {
    collapserMarkup = (
      <button
        type="button"
        className={css.seeAllAnswers}
        onClick={onSetAskAnswersCollapsed.bind(null, questionKey, !answersCollapsed)}
        aria-describedby={describedbyId}
        data-test-id={testId('seeHideAllAnswersCustomerQuestions')}
      >
        {seeOrHide} All Answers
      </button>
    );
  }

  const placeholder = answerEls.length === 0 ? 'Be the first to answer this question!' : 'Respond to this question';
  let answeringSection;
  if (answering || answerEls.length === 0) {
    answeringSection = (
      <ItemForm
        onAskAuthRedirect={onAskAuthRedirect}
        onSubmitAskItem={onSubmitAskItem}
        questionKey={questionKey}
        textareaPlaceholder={placeholder}
        authenticated={authenticated}
        shown={answering}
      />
    );
  } else {
    const openAnswerDialog = () => onSetAnsweringAskQuestion(questionKey, true);
    answeringSection = (
      <button
        type="button"
        className={css.buttonToAnswerQuestion}
        onClick={openAnswerDialog}
        aria-describedby={describedbyId}
        data-test-id={testId('customerQuestionsSubmitAnswer')}
      >
        Submit Answer
      </button>
    );
  }

  return (
    <div>
      {answerEls[0]}
      {collapserMarkup}
      {collapsibleMarkup}
      {answeringSection}
    </div>
  );
};

export default AnswerList;

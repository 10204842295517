import { stringify } from 'query-string';

import timedFetch from 'middleware/timedFetch';
import type { AskData, HmdOptions, HmdResponse, PostAskResponse, VotesData } from 'types/ask';

/**
 * Get the Zappos Ask data (questions and answers) for a specified marketplace
 * ID and product ID.
 *
 * @param  string   url             the Zappos Ask service endpoint
 * @param  string   marketId        the obfuscated marketplace ID
 * @param  string   productId       the product ID
 * @param  function [fetcher=fetch] fetch or fetch-like implementation
 * @return object                   promise
 */
function fetchData(url: string, marketId: string, productId: string, fetcher = timedFetch('fetchAskData')): Promise<Response<AskData>> {
  return fetcher(`${url}/questionsAndAnswers/${marketId}${productId}`);
}

/** Fetch the authenticated customer's votes. */
function fetchMyVotes(url: string, marketId: string, productId: string, fetcher = timedFetch('fetchMyAskItemVotes')): Promise<Response<VotesData>> {
  const body = JSON.stringify({ marketIdProductId: marketId + productId });
  const opts = { credentials: 'include', method: 'POST', body };
  return fetcher(`${url}/GetCustomerUpvotes`, opts);
}

/**
 * Write a new Ask question or answer. If it's a question, make sure
 * parentItemId is null.
 */
function writeItem(
  url: string,
  marketId: string,
  productId: string,
  parentItemId: string | null | undefined,
  text: string,
  fetcher = timedFetch('writeAskItem')
): Promise<Response<PostAskResponse>> {
  const body = {
    marketIdProductId: `${marketId}${productId}`,
    parentQAItemId: parentItemId || 'NONE',
    text
  };
  const opts = {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(body)
  };
  return fetcher(`${url}/WriteQAItem`, opts);
}

function changeVote<T>(
  url: string,
  marketId: string,
  productId: string,
  qAItemId: string,
  fetcher: (...args: any[]) => Promise<Response<T>>,
  endpoint: string
) {
  const body = JSON.stringify({
    marketIdProductId: marketId + productId,
    qAItemId
  });
  const opts = { credentials: 'include', method: 'POST', body };
  return fetcher(`${url}/${endpoint}`, opts);
}

/** Vote a question or answer up. */
function upvote(
  url: string,
  marketId: string,
  productId: string,
  qAItemId: string,
  fetcher: (...args: any[]) => Promise<Response<PostAskResponse>> = timedFetch('upvoteAskItem') as any
) {
  return changeVote(url, marketId, productId, qAItemId, fetcher, 'VoteUp');
}

/** Vote a question or answer down. */
function downvote(
  url: string,
  marketId: string,
  productId: string,
  qAItemId: string,
  fetcher: (...args: any[]) => Promise<Response<PostAskResponse>> = timedFetch('downvoteAskItem') as any
) {
  return changeVote(url, marketId, productId, qAItemId, fetcher, 'RemoveVoteUp');
}

/** Report a question or answer. */
function reportItem(
  url: string,
  marketId: string,
  productId: string,
  qAItemId: string,
  fetcher = timedFetch('reportAskItem')
): Promise<Response<{ success: boolean }>> {
  const body = JSON.stringify({
    marketIdProductId: marketId + productId,
    qAItemId,
    reportedById: 'police'
  });
  const opts = { credentials: 'include', method: 'POST', body };
  return fetcher(`${url}/ReportQAItem`, opts);
}

function getHmdSurvey(url: string, { pollId, key }: HmdOptions, fetcher = timedFetch('getAskSurvey')): Promise<Response<HmdResponse>> {
  return fetcher(`${url}/GetSurvey?${stringify({ pollId, key })}`);
}

function submitHmdSurvey(url: string, data: any, fetcher = timedFetch('getAskSurvey')): Promise<Response<HmdResponse>> {
  return fetcher(`${url}/SubmitSurvey`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(data)
  });
}

export default {
  fetchData,
  fetchMyVotes,
  writeItem,
  upvote,
  downvote,
  reportItem,
  getHmdSurvey,
  submitHmdSurvey
};

import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { AskAnswer, AskQuestion } from 'reducers/ask/ask';

import css from 'styles/components/productdetail/ask/voteBox.scss';

const makeReportStuff = ({ item: { justReported, key }, onReportAskItem }: Props) => {
  let reportClasses = null;
  let reportFn: (...args: any) => any = () => null;
  if (justReported) {
    reportClasses = cn(css.report, css.justReported);
  } else {
    reportClasses = css.report;
    reportFn = () => onReportAskItem(key);
  }
  return { reportClasses, reportFn };
};

const makeVoteFunctions = ({ item: { key, myVote }, onAddAskDownvote, onAddAskUpvote }: Props) => {
  let downvoteFn = () => onAddAskDownvote(key);
  let upvoteFn = () => onAddAskUpvote(key);
  if (myVote === -1) {
    downvoteFn = () => onAddAskUpvote(key);
  } else if (myVote === 1) {
    upvoteFn = () => onAddAskDownvote(key);
  }
  return { downvoteFn, upvoteFn };
};

interface Props {
  describedbyId: string;
  isQuestion: boolean;
  item: AskQuestion | AskAnswer;
  onAddAskDownvote: (key: string) => void;
  onAddAskUpvote: (key: string) => void;
  onReportAskItem: (key: string) => void;
}

const VoteBox = (props: Props) => {
  const {
    item: { justReported, myVote, upvotes, votes },
    isQuestion,
    describedbyId
  } = props;
  const { downvoteFn, upvoteFn } = makeVoteFunctions(props);
  const { reportClasses, reportFn } = makeReportStuff(props);
  const { testId } = useMartyContext();
  return (
    <div className={isQuestion ? css.questionVoteBox : css.answerVoteBox}>
      <button
        type="button"
        className={cn(css.upvote, { [css.voted]: myVote > 0 })}
        onClick={upvoteFn}
        aria-label={isQuestion ? 'Upvote Question' : 'Helpful Answer?'}
        aria-describedby={describedbyId}
        data-test-id={testId('upvoteButtonCustomerQuestions')}
      >
        {isQuestion || 'Helpful?'}
      </button>
      <span className={css.numVotes} data-test-id={testId('numVotesCustomerQuestions')}>
        {isQuestion ? votes : upvotes}
      </span>{' '}
      <span className={css.votesDescription}>{isQuestion ? 'Votes' : 'found this answer helpful.'}</span>
      {isQuestion && (
        <button
          type="button"
          className={cn(css.downvote, { [css.voted]: myVote < 0 })}
          onClick={downvoteFn}
          aria-label="Downvote Question"
          aria-describedby={describedbyId}
          data-test-id={testId('downvoteButtonCustomerQuestions')}
        />
      )}{' '}
      <button
        type="button"
        className={reportClasses}
        onClick={reportFn}
        aria-label={isQuestion ? 'Report abuse of question' : 'Report abuse of answer'}
        aria-describedby={describedbyId}
        data-test-id={testId('reportAbuseButtonCustomerQuestions')}
      >
        {isQuestion ? 'Report' : 'Report abuse'}
      </button>
      {justReported && <span data-test-id={testId('reportedNotificationCustomerQuestions')}>Reported</span>}
    </div>
  );
};

export default VoteBox;

import React, { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import ProductAwareAmethystViewableImpression from 'components/productdetail/ProductAwareAmethystViewableImpression';
import { evProductQuestionAnswerImpression } from 'events/ask';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import {
  addAskDownvote,
  addAskUpvote,
  askAuthRedirect,
  fetchAsk,
  reportAskItem,
  setAnsweringAskQuestion,
  setAskAnswersCollapsed,
  setAskQuestionsCollapsed,
  setViewingAskQuestionsDesktop,
  submitAskItem
} from 'actions/ask';
import AnswerList from 'components/productdetail/ask/AnswerList';
import ItemForm from 'components/productdetail/ask/ItemForm';
import VoteBox from 'components/productdetail/ask/VoteBox';
import { SmallLoader } from 'components/Loader';
import { trackEvent } from 'helpers/analytics';
import { formatAskAnswerTrackEventParams, formatAskQuestionTrackEventParams, formatAskTrackEventParams } from 'helpers/AskUtils';
import { parseTextToReact } from 'helpers/parseToReact';
import type { AppState } from 'types/app';
import type { AskQuestion, AskState } from 'reducers/ask/ask';
import type { ProductDetailState } from 'reducers/detail/productDetail';
import { MartyContext } from 'utils/context';

import css from 'styles/containers/ask.scss';

interface Params {
  productId: string;
  colorId?: string;
  seoName?: string;
}

interface OwnProps {
  params: Params;
  product: ProductDetailState;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

export class Ask extends Component<Props> {
  componentDidMount() {
    this.props.fetchAsk(this.props.params);
  }

  onReportAskQuestion = (key: string) => {
    const {
      reportAskItem,
      params: { productId }
    } = this.props;
    trackEvent('TE_ASK_REPORT_QUESTION', formatAskQuestionTrackEventParams(productId, key));
    reportAskItem(productId, key);
  };

  onReportAskAnswer = (key: string) => {
    const {
      reportAskItem,
      params: { productId }
    } = this.props;
    trackEvent('TE_ASK_REPORT_ANSWER', formatAskAnswerTrackEventParams(productId, key));
    reportAskItem(productId, key);
  };

  onAddAskQuestionUpvote = (key: string) => {
    const {
      addAskUpvote,
      params: { productId, colorId }
    } = this.props;
    trackEvent('TE_ASK_UPVOTE_QUESTION', formatAskQuestionTrackEventParams(productId, key));
    addAskUpvote(productId, colorId, key);
  };

  onAddAskAnswerUpvote = (key: string) => {
    const {
      addAskUpvote,
      params: { productId, colorId }
    } = this.props;
    trackEvent('TE_ASK_UPVOTE_ANSWER', formatAskAnswerTrackEventParams(productId, key));
    addAskUpvote(productId, colorId, key);
  };

  onAddAskQuestionDownvote = (key: string) => {
    const {
      addAskDownvote,
      params: { productId, colorId }
    } = this.props;
    trackEvent('TE_ASK_DOWNVOTE_QUESTION', formatAskQuestionTrackEventParams(productId, key));
    addAskDownvote(productId, colorId, key);
  };

  onAddAskAnswerDownvote = (key: string) => {
    const {
      addAskDownvote,
      params: { productId, colorId }
    } = this.props;
    trackEvent('TE_ASK_DOWNVOTE_ANSWER', formatAskAnswerTrackEventParams(productId, key));
    addAskDownvote(productId, colorId, key);
  };

  onAskAuthRedirect = () => {
    const {
      askAuthRedirect,
      params: { productId, colorId }
    } = this.props;
    askAuthRedirect(productId, colorId);
  };

  onSetAnsweringAskQuestion = (key: string, answering: boolean) => {
    const {
      params: { productId },
      setAnsweringAskQuestion
    } = this.props;
    const event = answering ? 'TE_ASK_VIEW_ANSWER_FORM' : 'TE_ASK_LEAVE_ANSWER_FORM';
    trackEvent(event, formatAskQuestionTrackEventParams(productId, key));
    setAnsweringAskQuestion(key, answering);
  };

  toggleAskQuestionsCollapsed = () => {
    const {
      data: { questionsCollapsed },
      params: { productId },
      setAskQuestionsCollapsed
    } = this.props;
    const event = questionsCollapsed ? 'TE_ASK_EXPAND_QUESTIONS' : 'TE_ASK_COLLAPSE_QUESTIONS';
    trackEvent(event, formatAskTrackEventParams(productId));
    setAskQuestionsCollapsed(!questionsCollapsed);
  };

  onSetAskAnswersCollapsed = (questionKey: string, collapsed: boolean) => {
    const {
      params: { productId },
      setAskAnswersCollapsed
    } = this.props;
    const event = collapsed ? 'TE_ASK_COLLAPSE_ANSWERS' : 'TE_ASK_EXPAND_ANSWERS';
    trackEvent(event, formatAskQuestionTrackEventParams(productId, questionKey));
    setAskAnswersCollapsed(questionKey, collapsed);
  };

  toggleViewingAskQuestions = () => {
    const {
      props: {
        data: { viewingQuestionsDesktop: viewingQuestions },
        params: { productId },
        setViewingAskQuestionsDesktop
      }
    } = this;
    const event = viewingQuestions ? 'TE_ASK_VIEW_QUESTION_FORM' : 'TE_ASK_LEAVE_QUESTION_FORM';
    trackEvent(event, formatAskTrackEventParams(productId));
    setViewingAskQuestionsDesktop(!viewingQuestions);
  };

  onSubmitAskItem = (parentKey?: string | null, text?: string) => {
    const {
      submitAskItem,
      params: { productId, colorId }
    } = this.props;

    let event;
    const eventParams = parentKey ? formatAskQuestionTrackEventParams(productId, parentKey) : formatAskTrackEventParams(productId);

    if (text) {
      event = parentKey ? 'TE_ASK_SUBMIT_ANSWER' : 'TE_ASK_SUBMIT_QUESTION';
      submitAskItem(productId, colorId, parentKey, text);
    } else {
      event = parentKey ? 'TE_ASK_SUBMIT_EMPTY_ANSWER' : 'TE_ASK_SUBMIT_EMPTY_QUESTION';
    }

    trackEvent(event, eventParams);
  };

  makeQuestionMarkup = (question: AskQuestion) => {
    const { key, text } = question;
    const {
      props: {
        data: { authenticated, questionsCollapsed },
        product
      },
      onAskAuthRedirect,
      onAddAskAnswerDownvote,
      onAddAskAnswerUpvote,
      onAddAskQuestionDownvote,
      onAddAskQuestionUpvote,
      onReportAskAnswer,
      onReportAskQuestion,
      onSetAnsweringAskQuestion,
      onSetAskAnswersCollapsed,
      onSubmitAskItem
    } = this;
    const questionId = `customerQuestion${key}`;
    return (
      <MartyContext.Consumer key={key}>
        {({ testId }) => (
          <ProductAwareAmethystViewableImpression event={evProductQuestionAnswerImpression} question={question} product={product}>
            <div className={css.question} data-test-id={testId('questionContainer')}>
              <div className={css.questionHeading}>Question</div>
              <p id={questionId} className={css.questionText}>
                {parseTextToReact(text)}
              </p>
              <VoteBox
                item={question}
                isQuestion={true}
                onAddAskDownvote={onAddAskQuestionDownvote}
                onAddAskUpvote={onAddAskQuestionUpvote}
                onReportAskItem={onReportAskQuestion}
                describedbyId={questionId}
              />
              <div className={css.answerListForQuestion}>
                <AnswerList
                  authenticated={authenticated}
                  question={question}
                  onAddAskDownvote={onAddAskAnswerDownvote}
                  onAddAskUpvote={onAddAskAnswerUpvote}
                  onReportAskAnswer={onReportAskAnswer}
                  onSetAnsweringAskQuestion={onSetAnsweringAskQuestion}
                  onSetAskAnswersCollapsed={onSetAskAnswersCollapsed}
                  onAskAuthRedirect={onAskAuthRedirect}
                  onSubmitAskItem={onSubmitAskItem}
                  product={product}
                  describedbyId={questionId}
                />
              </div>
              {!questionsCollapsed && <hr className="my-4 border-subtle" />}
            </div>
          </ProductAwareAmethystViewableImpression>
        )}
      </MartyContext.Consumer>
    );
  };

  render() {
    return (
      <MartyContext.Consumer>
        {({ testId }) => {
          const {
            props: {
              data: { questionsCollapsed: collapsed, viewingQuestionsDesktop: viewingQuestions, askError, authenticated, loading, questions }
            },
            toggleAskQuestionsCollapsed,
            toggleViewingAskQuestions
          } = this;

          if (askError) {
            return null;
          }

          const itemFormProps = {
            questionKey: null,
            textareaPlaceholder: 'Ask the Zappos Community',
            authenticated,
            onSetAnsweringAskQuestion: this.onSetAnsweringAskQuestion,
            onAskAuthRedirect: this.onAskAuthRedirect,
            onSubmitAskItem: this.onSubmitAskItem,
            shown: !viewingQuestions
          };

          let askAQuestionButton;
          let askBody = null;

          if (loading) {
            askBody = <SmallLoader />;
          } else {
            if (questions && questions.length > 0) {
              const text = viewingQuestions ? 'Ask a Question' : 'View Questions';
              askAQuestionButton = (
                <button
                  type="button"
                  className={css.askAQuestionButton}
                  onClick={toggleViewingAskQuestions}
                  data-test-id={testId('askViewQuestions')}
                >
                  {text}
                </button>
              );
              const questionsMarkup = questions.map(question => this.makeQuestionMarkup(question));
              let collapser = null,
                collapsibleMarkup = null;
              if (questions.length > 1) {
                collapsibleMarkup = collapsed ? null : <div>{questionsMarkup.slice(1)}</div>;
                const seeOrHide = collapsed ? 'See' : 'Hide';
                const optionalCount = collapsed ? ` ${questions.length}` : null;
                collapser = (
                  <button
                    type="button"
                    className={css.questionsCollapser}
                    onClick={toggleAskQuestionsCollapsed}
                    data-test-id={testId('seeHideAllCustomerQuestions')}
                  >
                    {seeOrHide} All{optionalCount} Customer Questions
                  </button>
                );
              }
              askBody = (
                <div className={css.body}>
                  {questionsMarkup[0]}
                  {collapsibleMarkup}
                  {collapser}
                  <hr className="my-4 border-subtle" />
                </div>
              );
            }
          }

          let askAQuestionForm = null;
          if (!loading) {
            askAQuestionForm = (
              <div className={css.askQuestionForm}>
                <ItemForm {...itemFormProps} />
              </div>
            );
          }

          return (
            <div
              id="questions"
              className={cn(css.ask, {
                [css.viewingQuestions]: viewingQuestions && questions && questions.length > 0
              })}
              data-test-id={testId('customerQuestionsSection')}
            >
              <h2 className={css.banner}>Customer Questions</h2>
              {askAQuestionButton}
              {askAQuestionForm}
              {askBody}
              {!askBody && <hr className="my-4 border-subtle" />}
            </div>
          );
        }}
      </MartyContext.Consumer>
    );
  }
}

export function mapStateToProps(state: AppState) {
  const data: AskState = state.ask;
  return {
    data
  };
}

export const mapDispatchToProps = {
  addAskUpvote,
  addAskDownvote,
  askAuthRedirect,
  fetchAsk,
  reportAskItem,
  setAnsweringAskQuestion,
  setAskQuestionsCollapsed,
  setAskAnswersCollapsed,
  setViewingAskQuestionsDesktop,
  submitAskItem
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ConnectedAsk = connector(Ask);
export default withErrorBoundary('ConnectedAsk', ConnectedAsk);

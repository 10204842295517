import type { ThunkAction } from 'redux-thunk';
import type { AnyAction, Dispatch } from 'redux';

import { LOCATION_ASSIGN } from 'constants/appConstants';
import {
  ASK_DATA_ERROR,
  CHANGE_VOTE_ON_ASK_ITEM,
  MY_ASK_VOTES_ERROR,
  NEW_ASK_ITEM,
  RECEIVE_ASK_DATA,
  RECEIVE_MY_ASK_VOTES,
  REDIRECT,
  REPORT_ASK_ITEM,
  SET_ANSWERING_ASK_QUESTION,
  SET_ASK_ANSWERS_COLLAPSED,
  SET_ASK_HMD_SURVEY_DATA,
  SET_ASK_QUESTIONS_COLLAPSED,
  SET_FEDERATED_LOGIN_MODAL_VISIBILITY,
  SET_VIEWING_ASK_QUESTIONS_DESKTOP
} from 'constants/reduxActions';
import api from 'apis/ask';
import { setResponseStatus } from 'actions/client';
import { trackError } from 'helpers/ErrorUtils';
import marketplace from 'cfg/marketplace.json';
import { FetchError, fetchErrorMiddleware, fetchErrorMiddlewareAllowedErrors } from 'middleware/fetchErrorMiddleware';
import { buildAuthenticationRedirectUrl } from 'utils/redirect';
import { trackEvent } from 'helpers/analytics';
import type { AppState } from 'types/app';
import type { AskData, AskItem, HmdOptions, HmdResponse, VotesData } from 'types/ask';
const {
  api: {
    ask: { url: askUrl, obfuscatedMarketplaceId: marketplaceId }
  },
  hasFederatedLogin
} = marketplace;

interface SetLoginModalVisibilityAction {
  type: typeof SET_FEDERATED_LOGIN_MODAL_VISIBILITY;
  payload: {
    isFederatedLoginModalShowing: boolean;
    returnTo: string;
  };
}

interface RedirectAction {
  type: typeof REDIRECT;
  location: string;
  method: typeof LOCATION_ASSIGN;
}

export function askAuthRedirect(productId: string, colorId?: string): SetLoginModalVisibilityAction | RedirectAction {
  const redirectUrl = `/product/${productId}${colorId ? `/color/${colorId}` : ''}`;
  if (hasFederatedLogin) {
    return {
      type: SET_FEDERATED_LOGIN_MODAL_VISIBILITY,
      payload: {
        isFederatedLoginModalShowing: true,
        returnTo: redirectUrl
      }
    } as const;
  }
  return {
    type: REDIRECT,
    location: buildAuthenticationRedirectUrl(redirectUrl),
    method: LOCATION_ASSIGN
  } as const;
}

/**
 * Promise middleware that redirects if ZapposAsk gave us an error cause user
 * isn't authenticated.
 */
export function authRedirectMiddleware<T extends { error: string | null }>(dispatch: Dispatch, productId: string, colorId?: string) {
  return function (response: T) {
    if (response.error === '401') {
      dispatch(askAuthRedirect(productId, colorId));
    }
    return response;
  };
}

function addVote(productId: string, colorId: string | undefined, qAItemId: string, delta: number): ThunkAction<void, AppState, void, AnyAction> {
  return function (dispatch) {
    dispatch({ type: CHANGE_VOTE_ON_ASK_ITEM, data: { key: qAItemId, delta } });
    const fn = delta < 0 ? 'downvote' : 'upvote';
    api[fn](askUrl, marketplaceId, productId, qAItemId).then(response => {
      if (response.status === 401) {
        dispatch(askAuthRedirect(productId, colorId));
      } else if (response.status >= 400) {
        throw new FetchError(response.url, response.status, response.statusText);
      }
      return response.json();
    });
  };
}

export function addAskUpvote(productId: string, colorId: string | undefined, qAItemId: string) {
  return addVote(productId, colorId, qAItemId, 1);
}

export function addAskDownvote(productId: string, colorId: string | undefined, qAItemId: string) {
  return addVote(productId, colorId, qAItemId, -1);
}

export function setAnsweringAskQuestion(key: string, answering: boolean) {
  return {
    type: SET_ANSWERING_ASK_QUESTION,
    data: { key, answering }
  } as const;
}

export function setAskAnswersCollapsed(questionKey: string, collapsed: boolean) {
  return {
    type: SET_ASK_ANSWERS_COLLAPSED,
    data: { questionKey, collapsed }
  } as const;
}

export function setAskQuestionsCollapsed(collapsed: boolean) {
  return { type: SET_ASK_QUESTIONS_COLLAPSED, data: { collapsed } } as const;
}

export function setViewingAskQuestionsDesktop(viewing: boolean) {
  return {
    type: SET_VIEWING_ASK_QUESTIONS_DESKTOP,
    data: { viewing }
  } as const;
}

export function submitAskItem(
  productId: string,
  colorId: string | undefined,
  parentQAItemId: string | undefined | null,
  text: string
): ThunkAction<void, AppState, void, AnyAction> {
  return function (dispatch) {
    if (parentQAItemId) {
      dispatch(setAnsweringAskQuestion(parentQAItemId, false));
      dispatch(setAskAnswersCollapsed(parentQAItemId, false));
    } else {
      dispatch(setViewingAskQuestionsDesktop(true));
    }
    api
      .writeItem(askUrl, marketplaceId, productId, parentQAItemId, text)
      .then(fetchErrorMiddleware)
      .then(authRedirectMiddleware(dispatch, productId, colorId))
      .then(response => {
        const data = { parentKey: parentQAItemId, newItem: response.qAItem };
        dispatch({ type: NEW_ASK_ITEM, data });
      });
  };
}

function receiveAskData(response: AskData) {
  const type = response.error ? ASK_DATA_ERROR : RECEIVE_ASK_DATA;
  return { type, data: response } as const;
}

function receiveMyAskVotes(response: VotesData) {
  const type = response.error ? MY_ASK_VOTES_ERROR : RECEIVE_MY_ASK_VOTES;
  return { type, data: response } as const;
}

export function reportAskItem(productId: string, qAItemId: string) {
  api.reportItem(askUrl, marketplaceId, productId, qAItemId).then(fetchErrorMiddleware);
  return { type: REPORT_ASK_ITEM, data: qAItemId } as const;
}

interface FetchAskArgs {
  productId: string;
  localFetchData?: (url: string, marketId: string, productId: string, fetcher?: Function) => Promise<Response<AskData>>;
  localFetchMyVotes?: (url: string, marketId: string, productId: string, fetcher?: Function) => Promise<Response<VotesData>>;
}

export function fetchAsk(args: FetchAskArgs): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  const { localFetchData = api.fetchData, localFetchMyVotes = api.fetchMyVotes, productId } = args;

  return function (dispatch, getState) {
    const { cookies } = getState();
    const xMainCookie = cookies && cookies['x-main'];
    const shouldFetchMyVotes = !!xMainCookie;

    const data = localFetchData(askUrl, marketplaceId, productId).then(fetchErrorMiddleware);

    if (!shouldFetchMyVotes) {
      return data.then(res => {
        dispatch(receiveAskData(res));
      });
    }

    const votes = localFetchMyVotes(askUrl, marketplaceId, productId).then(fetchErrorMiddlewareAllowedErrors([401]));

    return Promise.all([data, votes]).then(([dataRes, votesRes]) => {
      dispatch(receiveAskData(dataRes));
      dispatch(receiveMyAskVotes(votesRes));
    });
  };
}

export function setHmdSurveyMarkupData(data: HmdResponse) {
  return {
    type: SET_ASK_HMD_SURVEY_DATA,
    data
  } as const;
}

export function getHmdSurveyMarkupData({ pollId, key }: HmdOptions, getHmdSurvey = api.getHmdSurvey): ThunkAction<void, AppState, void, AnyAction> {
  return dispatch =>
    getHmdSurvey(askUrl, { pollId, key })
      .then(fetchErrorMiddleware)
      .then(res => {
        dispatch(setHmdSurveyMarkupData(res));
      })
      .catch(err => {
        trackError('ERROR', 'Failed to fetch HMD survey data', err);
        dispatch(setResponseStatus(404));
      });
}

// TODO ts ; I didn't have access to any HMD Surveys during the typing of this, so the 'data' that's being submitted is just typed as any
export function submitHmdSurvey(
  data: { answers: any },
  submitHmdSurvey = api.submitHmdSurvey,
  performTrackEvent = trackEvent
): ThunkAction<void, AppState, void, AnyAction> {
  return dispatch =>
    submitHmdSurvey(askUrl, data)
      .then(fetchErrorMiddleware)
      .then(res => {
        performTrackEvent('TE_HMD_SURVEY_SUBMITTED');
        return dispatch(setHmdSurveyMarkupData(res));
      })
      .catch(err => {
        trackError('ERROR', 'Failed to submit HMD survey data', err);
      });
}

type NewAskItem = {
  type: typeof NEW_ASK_ITEM;
  data: {
    parentKey: string | undefined;
    newItem: AskItem;
  };
};

export type AskAction =
  | ReturnType<typeof setHmdSurveyMarkupData>
  | ReturnType<typeof askAuthRedirect>
  | ReturnType<typeof setAnsweringAskQuestion>
  | ReturnType<typeof setAskAnswersCollapsed>
  | ReturnType<typeof setAskQuestionsCollapsed>
  | ReturnType<typeof setViewingAskQuestionsDesktop>
  | ReturnType<typeof receiveAskData>
  | ReturnType<typeof receiveMyAskVotes>
  | ReturnType<typeof reportAskItem>
  | {
      type: typeof CHANGE_VOTE_ON_ASK_ITEM;
      data: { key: string; delta: number };
    }
  | NewAskItem;
